import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SettingService from "services/SettingService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getAllMenuCate = createAsyncThunk(
  "setting/getAllMenuCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllMenuCate();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addMenuCate = createAsyncThunk(
  "setting/addMenuCate",
  async (data, { rejectWithValue }) => {
    const { onSuccess } = data;
    try {
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.AddMenuCate(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateMenuCate = createAsyncThunk(
  "setting/updateMenuCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpdateMenuCate(data);
      if (response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      } else {
        openNotification(
          "warning",
          response.data.description,
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delMenuCate = createAsyncThunk(
  "setting/delMenuCate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SettingService.DeleteMenuCate(id);
      if (response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBankList = createAsyncThunk(
  "setting/getBankList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetBankList();
      return JSON.parse(response.data);
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dataList: [],
  dataSource: [],
  bankList: [],
};
export const settingMenuCateSlice = createSlice({
  name: "settingMenuCate",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDataList: (state, { payload }) => {
      state.dataList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMenuCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMenuCate.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
        state.dataSource = action.payload;
      })
      .addCase(getAllMenuCate.rejected, (state, action) => {
        state.loading = false;
      })
      //add cate
      .addCase(addMenuCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMenuCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addMenuCate.rejected, (state, action) => {
        state.loading = false;
      })
      //update cate
      .addCase(updateMenuCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMenuCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateMenuCate.rejected, (state, action) => {
        state.loading = false;
      })
      //del cate
      .addCase(delMenuCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(delMenuCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delMenuCate.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getBankList.fulfilled, (state, action) => {
        state.bankList = action.payload.data;
      });
  },
});

export const { showLoading, setDataList } = settingMenuCateSlice.actions;

export default settingMenuCateSlice.reducer;
