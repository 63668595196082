import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

export const getTermCondition = createAsyncThunk(
  "setting/getTermCondition",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetTermCondition();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getPrivacy = createAsyncThunk(
  "setting/getPrivacy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetPrivacy();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getAffiliateSetting = createAsyncThunk(
  "setting/getAffiliateSetting",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAffiliateSetting();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSetAffiliateSetting = createAsyncThunk(
  "setting/upSetAffiliateSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpdateAffiliateSetting(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getArtificialIntelligence = createAsyncThunk(
  "setting/getArtificialIntelligence",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetArtificialIntelligence();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSetArtificialIntelligence = createAsyncThunk(
  "setting/upSetArtificialIntelligence",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpSetArtificialIntelligence(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  termCondition: {},
  affiliateSetting: {},
  privacy: {},
  artificialSetting: {},
};
export const SettingSystem = createSlice({
  name: "setting-system",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTermCondition.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTermCondition.fulfilled, (state, action) => {
        state.loading = false;
        state.termCondition = action.payload;
      })
      .addCase(getTermCondition.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPrivacy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrivacy.fulfilled, (state, action) => {
        state.loading = false;
        state.privacy = action.payload;
      })
      .addCase(getPrivacy.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAffiliateSetting.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAffiliateSetting.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAffiliateSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.affiliateSetting = action.payload;
      })
      .addCase(upSetAffiliateSetting.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(upSetAffiliateSetting.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upSetAffiliateSetting.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getArtificialIntelligence.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getArtificialIntelligence.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getArtificialIntelligence.fulfilled, (state, action) => {
        state.loading = false;
        state.artificialSetting = action.payload;
      })
      .addCase(upSetArtificialIntelligence.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(upSetArtificialIntelligence.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upSetArtificialIntelligence.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = SettingSystem.actions;

export default SettingSystem.reducer;
