import axiosInstance from "../auth/FetchInterceptor";
import {
  SEARCH_AFFILIATE,
  SEARCH_USER,
  UP_SET_AFFILIATE,
  CHECK_EXISTED_AFFILIATE,
  DELETE_AFFILIATE,
  GET_PAYMENT_PRICE,
  CONFIRM_PAYMENT,
  PAYMENT_HISTORY,
  GET_UN_AGENCY,
} from "../constants/ApiConstant";

const AffiliateService = {};

AffiliateService.getAffiliateData = function (data) {
  return axiosInstance.post(SEARCH_AFFILIATE, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.addAffiliateUser = function (data) {
  return axiosInstance.post(UP_SET_AFFILIATE, data,{baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.updateAffiliateUser = function (data) {
  return axiosInstance.put(UP_SET_AFFILIATE, data,{baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.deleteAffiliateUser = function (data) {
  return axiosInstance.put(DELETE_AFFILIATE, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.checkAffiliateUser = function (id) {
  return axiosInstance.get(`${CHECK_EXISTED_AFFILIATE}/${id}`, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.getUserAccount = function (data) {
  return axiosInstance.post(SEARCH_USER, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.getPaymentPrice = function (data) {
  return axiosInstance.post(GET_PAYMENT_PRICE, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.confirmPayment = function (data) {
  return axiosInstance.post(CONFIRM_PAYMENT, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.getPaymentHistory = function (data) {
  return axiosInstance.post(PAYMENT_HISTORY, data, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

AffiliateService.getUnAgency = function (id) {
  return axiosInstance.get(`${GET_UN_AGENCY}/${id}`, {baseURL: process.env.REACT_APP_API_ENDPOINT_URL});
};

export default AffiliateService;
