import axiosInstance from "auth/FetchInterceptor";
import {
  SETTING_STORE_CATEGORY_API,
  SETTING_MENU_CATEGORY_API,
  SETTING_STORE_UTILITY_API,
  SETTING_PRODUCT_ATTRIBUTE,
  SETTING_BANNER,
  SETTING_PRIVACY,
  SETTING_TERM_CONDITION,
  SETTING_GROUP_CATEGORY_API,
  SETTING_RATING_CATEGORY_API,
  GET_SETTING_RATE,
  SETTING_AFFILIATE,
  GET_BANK_QR,
  SETTING_ARTIFICIAL_INTELLIGENCE,
} from "constants/ApiConstant";
const SettingService = {};

//Store Category
SettingService.GetAllStoreCate = function () {
  return axiosInstance.get(SETTING_STORE_CATEGORY_API, null);
};
SettingService.AddStoreCate = function (data) {
  return axiosInstance.post(SETTING_STORE_CATEGORY_API, data);
};
SettingService.UpdateStoreCate = function (data) {
  return axiosInstance.put(SETTING_STORE_CATEGORY_API, data);
};
SettingService.DeleteStoreCate = function (id) {
  return axiosInstance.delete(`${SETTING_STORE_CATEGORY_API}/${id}`, null);
};

//Store Menu Category
SettingService.GetAllMenuCate = function () {
  return axiosInstance.get(SETTING_MENU_CATEGORY_API, null);
};
SettingService.AddMenuCate = function (data) {
  return axiosInstance.post(SETTING_MENU_CATEGORY_API, data);
};
SettingService.UpdateMenuCate = function (data) {
  return axiosInstance.put(SETTING_MENU_CATEGORY_API, data);
};
SettingService.DeleteMenuCate = function (id) {
  return axiosInstance.delete(`${SETTING_MENU_CATEGORY_API}/${id}`, null);
};

//Store Utility
SettingService.GetAllStoreUtility = function () {
  return axiosInstance.get(SETTING_STORE_UTILITY_API, null);
};

SettingService.AddStoreUtility = function (data) {
  return axiosInstance.post(SETTING_STORE_UTILITY_API, data);
};
SettingService.UpdateStoreUtility = function (data) {
  return axiosInstance.put(SETTING_STORE_UTILITY_API, data);
};
SettingService.DeleteStoreUtility = function (id) {
  return axiosInstance.delete(`${SETTING_STORE_UTILITY_API}/${id}`, null);
};

//Product Attribute
SettingService.getProductAttribute = function () {
  return axiosInstance.get(SETTING_PRODUCT_ATTRIBUTE, null);
};

SettingService.GetStoreBanner = function () {
  return axiosInstance.get(SETTING_BANNER, null);
};
SettingService.AddStoreBanner = function (data) {
  return axiosInstance.post(SETTING_BANNER, data);
};
SettingService.UpdateStoreBannerState = function (data) {
  return axiosInstance.put(`${SETTING_BANNER}/change-status`, data);
};
SettingService.UpdateStoreBanner = function (data) {
  return axiosInstance.put(SETTING_BANNER, data);
};
SettingService.DeleteStoreBanner = function (id) {
  return axiosInstance.delete(`${SETTING_BANNER}/${id}`, null);
};

// System setting
SettingService.GetTermCondition = function () {
  return axiosInstance.get(SETTING_TERM_CONDITION, null);
};
SettingService.GetPrivacy = function () {
  return axiosInstance.get(SETTING_PRIVACY, null);
};
SettingService.GetBankList = function () {
  return axiosInstance.get(GET_BANK_QR, null);
};

// Group category setting
SettingService.GetAllGroupCate = function () {
  return axiosInstance.get(SETTING_GROUP_CATEGORY_API, null);
};

SettingService.AddGroupCate = function (data) {
  return axiosInstance.post(SETTING_GROUP_CATEGORY_API, data);
};

SettingService.UpdateGroupCate = function (data) {
  return axiosInstance.put(SETTING_GROUP_CATEGORY_API, data);
};

SettingService.DeleteGroupCate = function (id) {
  return axiosInstance.delete(`${SETTING_GROUP_CATEGORY_API}/${id}`, null);
};

// Rating category setting
SettingService.GetAllRatingCate = function () {
  return axiosInstance.get(SETTING_RATING_CATEGORY_API, null);
};

SettingService.GetSettingRate = function () {
  return axiosInstance.get(GET_SETTING_RATE, null);
};

SettingService.UpdateSettingRate = function (data) {
  return axiosInstance.put(GET_SETTING_RATE, data);
};


SettingService.AddRatingCate = function (data) {
  return axiosInstance.post(SETTING_RATING_CATEGORY_API, data);
};

SettingService.UpdateRatingCate = function (data) {
  return axiosInstance.put(SETTING_RATING_CATEGORY_API, data);
};

SettingService.DeleteRatingCate = function (id) {
  return axiosInstance.delete(`${SETTING_RATING_CATEGORY_API}/${id}`, null);
};

// Affiliate setting
SettingService.GetAffiliateSetting = function () {
  return axiosInstance.get(SETTING_AFFILIATE, null);
};

SettingService.UpdateAffiliateSetting = function (data) {
  return axiosInstance.put(SETTING_AFFILIATE, data);
};

// Artificial intelligence setting
SettingService.GetArtificialIntelligence = function () {
  return axiosInstance.get(SETTING_ARTIFICIAL_INTELLIGENCE, null);
};

SettingService.UpSetArtificialIntelligence = function (data) {
  return axiosInstance.post(SETTING_ARTIFICIAL_INTELLIGENCE, data);
};

export default SettingService;
