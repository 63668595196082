import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    title: "Đăng nhập",
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "loginAdmin",
    path: `${AUTH_PREFIX_PATH}/login-admin`,
    title: "Đăng nhập tài khoản quản trị",
    component: React.lazy(() =>
        import("views/auth-views/authentication/loginAdmin")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    title: "Đăng ký",
    component: React.lazy(() =>
      import("views/auth-views/authentication/partner-register")
    ),
  },
  {
    key: "forgot-password",
    title: "Quên mật khẩu",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: "/reset-password",
    title: "Đặt lại mật khẩu",
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "partner-register",
    path: "/partner-register",
    title: "Đăng ký",
    component: React.lazy(() =>
      import("views/auth-views/authentication/partner-register/verify")
    ),
  },
  {
    key: "paymentResult",
    title: "",
    path: '/ReturnUrl',
    component: React.lazy(() =>
      import("views/app-views/saas/payment/index"),
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    title: "Default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "admin.settings.store-categories",
    title: "Loại hình kinh doanh",
    path: `${APP_PREFIX_PATH}/admin/settings/store-categories`,
    component: React.lazy(() =>
      import("views/app-views/admin/settings/store-category")
    ),
  },
  {
    key: "admin.settings.store-utilities",
    title: "Tiện ích nhà hàng",
    path: `${APP_PREFIX_PATH}/admin/settings/store-utilities`,
    component: React.lazy(() =>
      import("views/app-views/admin/settings/store-utility")
    ),
  },
  {
    key: "admin.settings.rating-categories",
    title: "Danh mục đánh giá",
    path: `${APP_PREFIX_PATH}/admin/settings/rating-category`,
    component: React.lazy(() =>
        import("views/app-views/admin/settings/rating-category")
    ),
  },
  {
    key: "admin.settings.group-categories",
    title: "Nhóm loại hình",
    path: `${APP_PREFIX_PATH}/admin/settings/group-category`,
    component: React.lazy(() =>
        import("views/app-views/admin/settings/group-category")
    ),
  },
  {
    key: "admin.settings.menu-categories",
    title: "Nhóm hàng hóa",
    path: `${APP_PREFIX_PATH}/admin/settings/menu-categories`,
    component: React.lazy(() =>
      import("views/app-views/admin/settings/menu-category")
    ),
  },
  {
    key: "admin.settings.electronic-invoice",
    title: "Hoá đơn điện tử",
    path: `${APP_PREFIX_PATH}/admin/settings/electronic-invoice`,
    component: React.lazy(() =>
      import("views/app-views/admin/settings/electronic-invoice")
    ),
  },
  {
    key: "admin-settings-store-banner",
    path: `${APP_PREFIX_PATH}/admin/settings/store-banner`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/admin/settings/store-banner")
    ),
  },
  {
    key: "admin-settings-report-violation",
    path: `${APP_PREFIX_PATH}/admin/settings/report-violation`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/admin/settings/report-violation")
    ),
  },
  {
    key: "admin-settings-word-violation",
    path: `${APP_PREFIX_PATH}/admin/settings/word-violation`,
    title: "",
    component: React.lazy(() =>
        import("views/app-views/admin/settings/word-violation")
    ),
  },
  {
    key: "admin.settings.homepage",
    title: "Trang chủ Mobile",
    path: `${APP_PREFIX_PATH}/admin/settings/homepage`,
    component: React.lazy(() =>
      import("views/app-views/admin/settings/hompage")
    ),
  },
  {
    key: "manager.restaurant",
    title: "Quản lý thương hiệu",
    path: `${APP_PREFIX_PATH}/manager/restaurant`,
    component: React.lazy(() => import("views/app-views/manager/restaurant")),
  },
  {
    key: "manager.product",
    title: "Quản lí thực đơn",
    path: `${APP_PREFIX_PATH}/manager/product`,
    component: React.lazy(() => import("views/app-views/manager/product")),
  },
  {
    key: "manager.product.uc",
    path: `${APP_PREFIX_PATH}/manager/products/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/manager/product/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "manager.product.uc",
    path: `${APP_PREFIX_PATH}/manager/products/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/manager/product/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "manager.table",
    path: `${APP_PREFIX_PATH}/manager/table`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/table")),
  },
  {
    key: "manager.table.qr-code",
    path: `${APP_PREFIX_PATH}/manager/table/print-qr-code`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/manager/table/component/PrintQRCode")
    ),
  },
  {
    key: "manager.account", // tài khoản ứng dụng
    path: `${APP_PREFIX_PATH}/manager/appaccount`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/appaccount")),
  },
  {
    key: "manager.customer",
    path: `${APP_PREFIX_PATH}/manager/customer`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/customer")),
  },
  {
    key: "manager.employee",
    path: `${APP_PREFIX_PATH}/manager/employee`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/employee")),
  },
  {
    key: "manager.partner",
    path: `${APP_PREFIX_PATH}/manager/partner`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/partner")),
  },
  {
    key: "manager.order",
    path: `${APP_PREFIX_PATH}/manager/order`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/order")),
  },
  {
    key: "manager.setting-account",
    path: `${APP_PREFIX_PATH}/manager/setting-account/*`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/setting-account")),
  },
  {
    key: "manager.notification",
    path: `${APP_PREFIX_PATH}/manager/notification/*`,
    title: "",
    component: React.lazy(() => import("views/app-views/manager/notification")),
  },
  {
    key: "dashboardaccount.default",
    title: "Default",
    path: `${APP_PREFIX_PATH}/dashboardsAccount/default`,
    component: React.lazy(() => import("views/app-views/manager/dashboardsAccount/default")),
  },
  {
    key: "dashboardaccount.saas",
    title: "Default",
    path: `${APP_PREFIX_PATH}/dashboardsAccount/saas`,
    component: React.lazy(() => import("views/app-views/manager/dashboardsAccount/saas")),
  },
  {
    key: "manager.post",
    title: "Quản lí bài viết",
    path: `${APP_PREFIX_PATH}/manager/post`,
    component: React.lazy(() => import("views/app-views/manager/post")),
  },
  {
    key: "manager.post.uc",
    path: `${APP_PREFIX_PATH}/manager/post/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/manager/post/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "manager.post.uc",
    path: `${APP_PREFIX_PATH}/manager/post/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/manager/post/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "sell.pos",
    title: "",
    path: `${APP_PREFIX_PATH}/sell/pos`,
    component: React.lazy(() => import("views/app-views/sell/pos")),
  },
  {
    key: "saasSetting",
    title: "",
    path: `${APP_PREFIX_PATH}/saas/setting`,
    component: React.lazy(() =>
      import("views/app-views/saas/setting/index"),
    ),
  },
  {
    key: "saasHistory",
    title: "",
    path: `${APP_PREFIX_PATH}/saas/history`,
    component: React.lazy(() =>
      import("views/app-views/saas/history/index"),
    ),
  },
  {
    key: "affiliateSetting",
    title: "",
    path: `${APP_PREFIX_PATH}/affiliate/setting`,
    component: React.lazy(() =>
        import("views/app-views/affiliate/setting/index"),
    ),
  },
  {
    key: "affiliatePersonal",
    title: "",
    path: `${APP_PREFIX_PATH}/affiliate/personal`,
    component: React.lazy(() =>
        import("views/app-views/affiliate/affiliatePersonal/index"),
    ),
  },
  {
    key: "affiliateAgency",
    title: "",
    path: `${APP_PREFIX_PATH}/affiliate/agency`,
    component: React.lazy(() =>
        import("views/app-views/affiliate/affiliateAgency/index"),
    ),
  },
  {
    key: "smenubookAI",
    title: "",
    path: `${APP_PREFIX_PATH}/admin/settings/ai`,
    component: React.lazy(() =>
        import("views/app-views/admin/settings/smenubook-ai/index"),
    ),
  },
];
