import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import AffiliateService from "../../services/AffiliateService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

const initialState = {
  loading: false,
  affiliateList: [],
};

export const getAffiliateData = createAsyncThunk(
  "getAffiliateData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.getAffiliateData(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getUserAccount = createAsyncThunk(
  "getUserAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.getUserAccount(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getPaymentPrice = createAsyncThunk(
  "getPaymentPrice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.getPaymentPrice(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const confirmPayment = createAsyncThunk(
  "confirmPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.confirmPayment(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getHistoryPayment = createAsyncThunk(
  "getHistoryPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.getPaymentHistory(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getUnAgencyList = createAsyncThunk(
  "getHistoryPayment",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.getUnAgency(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);


export const addAffiliateAccount = createAsyncThunk(
    "addAffiliateAccount",
    async (data, { rejectWithValue }) => {
      try {
        const response = await AffiliateService.addAffiliateUser(data);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);

export const updateAffiliateAccount = createAsyncThunk(
  "updateAffiliateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.updateAffiliateUser(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
    return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteAffiliateAccount = createAsyncThunk(
  "deleteAffiliateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.deleteAffiliateUser(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const checkAffiliateExisted = createAsyncThunk(
  "checkAffiliateExisted",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AffiliateService.checkAffiliateUser(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAffiliateData.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAffiliateData.fulfilled, (state, action) => {
      state.loading = false;
      state.affiliateList = action.payload;
    });

    builder.addCase(getAffiliateData.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addAffiliateAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addAffiliateAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addAffiliateAccount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateAffiliateAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateAffiliateAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateAffiliateAccount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteAffiliateAccount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteAffiliateAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteAffiliateAccount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(checkAffiliateExisted.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(checkAffiliateExisted.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(checkAffiliateExisted.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const {} = affiliateSlice.actions;

export default affiliateSlice.reducer;
